<script setup lang="ts">
import { get } from 'lodash'
const log = createLog('app:qrhunt:overview')

const { $headerBackTo, $qrHuntRouter, $e, $t, $toast } = useNuxtApp()
$headerBackTo({ name: PAGE.GAMES })

const qrHuntStore = useQRHuntStore()

const isBuisy = inject('page-loading', ref(false))

async function onStartOrResumeRoute(route: TransformedRoute) {
    if (isBuisy.value) {
        return
    }
    isBuisy.value = true

    switch (true) {
        case route.state === 'finished':
            $qrHuntRouter.push({ name: 'finished', params: toValue(route) })
            break
        case route.state === 'running':
            $qrHuntRouter.push('detail')
            break
        case route.state === 'paused':
            qrHuntStore.actionResumeRoute(route.uid)
            break
        case route.state === 'aborted':
        case typeof route.state === 'undefined':
            qrHuntStore.actionStartRoute(route.uid)
            break
        default:
            log.debug(`[onStartOrResumeRoute] unhandled state`)
            break
    }
}

onWSMessage<SessionChangeResponse>(SessionChangeResponseSchema, (data) => {
    if (data.content.activity !== 'qrhunt') {
        return
    }
    isBuisy.value = false

    if (data.content.state === 'running') {
        $qrHuntRouter.push('detail')
    }
})

onWSMessage<WsError>(ErrorSchema, (data) => {
    isBuisy.value = false
    const errorType = data.content.errorType
    const errorMessage = $e(data.content.errorType)

    if (errorType === 'conflictingSessionState') {
        $toast.warning(errorMessage.replaceAll('<br />', '\n'))
    }

    log.error(errorMessage)
})
</script>

<template>
    <div class="page-qrhunt">
        <RichText
            class="page-qrhunt__text rich-text--compact"
            :text="$t('page.qrhunt.introText')"
        />

        <div class="page-qrhunt__list">
            <h4 class="normal">{{ $t('page.qrhunt.routes.headline') }}</h4>
            <div
                v-if="qrHuntStore.loading"
                class="page-qrhunt__routes-loading"
            >
                <Icon name="loading-48x" />
            </div>
            <template v-else>
                <RouteList v-if="qrHuntStore.getRoutes.length > 0">
                    <template
                        :key="`${route.uid}-${route.state}`"
                        v-for="(route, _) in qrHuntStore.getRoutes"
                    >
                        <RouteItem
                            @click="onStartOrResumeRoute(route)"
                            v-bind="route"
                            :loading="isBuisy"
                            :disabled="isBuisy"
                        />
                    </template>
                </RouteList>
                <template v-else>
                    {{ $t('page.qrhunt.noRoutes') }}
                </template>
            </template>
        </div>
    </div>
</template>

<style lang="scss">
.page-qrhunt {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(86px, 1fr) auto;
    grid-template-areas:
        'text '
        '.    '
        'list ';

    &__text {
        grid-area: text;
    }

    &__list {
        grid-area: list;
    }

    &__routes-loading {
        width: 100%;
        aspect-ratio: 16/9;
        display: grid;
        place-content: center;
    }
}
</style>
