<script setup lang="ts">
import { type TransformedRoute } from '~/utils'

type Props = TransformedRoute & {
    disabled?: boolean
    icon?: string
    loading?: boolean
}

withDefaults(defineProps<Props>(), {
    disabled: false,
    icon: 'route-48x',
    loading: false,
})

const iconName = computed(() => (icon: string, state: TransformedRoute['state']) => {
    return {
        paused: 'pause-48x',
        finished: 'check-48x',
        running: icon,
        aborted: icon,
        default: icon,
    }[state ?? 'default']
})
</script>

<template>
    <ListItem
        :title="`${name}`"
        :description="description"
        :disabled="disabled"
        :loading="loading"
        class="route-item"
        :class="{
            [`route-item--state-${state}`]: state,
        }"
    >
        <template #pre-icon>
            <Icon
                fluid
                :circle="color"
                :name="iconName('route-48x', state)"
                :auto-color="['stroke']"
            />
        </template>

        <template #post-icon>
            <Icon
                v-if="state !== 'finished'"
                :name="loading ? 'link-loading-48x' : 'link-48x'"
            />
        </template>
    </ListItem>
</template>

<style lang="scss">
.route-item {
    .list-item__pre-icon {
        width: 48px;
        height: 48px;
    }

    .list-item__post-icon .linkLoadingSVG [fill].linkLoadingSVG__path {
        fill: v(color-text);
    }
    .list-item__post-icon :not(.linkLoadingSVG) [stroke] {
        fill: none;
    }

    &--state-finished {
        color: v(tropical-rain-forest-60);

        .list-item__pre-icon .icon {
            background-color: v(tropical-rain-forest-60) !important;
            color: v(wild-sand);
        }

        .list-item__title {
            color: inherit;
        }
    }
}
</style>
